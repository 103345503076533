import React from "react"
import hangeul01 from "../assets/coreen/Korean alphabet consonant song 001.mp4"
import hangeul02 from "../assets/coreen/Korean alphabet consonant song 002.mp4"
import hangeul03 from "../assets/coreen/Korean alphabet consonant song 003.mp4"
import hangeul04 from "../assets/coreen/Korean alphabet consonant song 004.mp4"
import hangeul05 from "../assets/coreen/Korean alphabet consonant song 005.mp4"
import hangeul06 from "../assets/coreen/Korean alphabet consonant song 006.mp4"
import hangeul07 from "../assets/coreen/Korean alphabet consonant song 007.mp4"
import hangeul08 from "../assets/coreen/Korean alphabet consonant song 008.mp4"
import hangeul09 from "../assets/coreen/Korean alphabet consonant song 009.mp4"
import hangeul10 from "../assets/coreen/Korean alphabet consonant song 010.mp4"
import hangeul11 from "../assets/coreen/Korean alphabet consonant song 011.mp4"
import hangeul12 from "../assets/coreen/Korean alphabet consonant song 012.mp4"
import hangeul13 from "../assets/coreen/Korean alphabet consonant song 013.mp4"
import hangeul14 from "../assets/coreen/Korean alphabet consonant song 014.mp4"
import hangeul15 from "../assets/coreen/Korean alphabet consonant song 015.mp4"

export default function Coreen() {
  return (
    <div>
      <video controls width="30%">
        <source src={hangeul01} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul02} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul03} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul04} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul05} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul06} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul07} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul08} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul09} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul10} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul11} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul12} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul13} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul14} type="video/mp4" />
      </video>
      <video controls width="30%">
        <source src={hangeul15} type="video/mp4" />
      </video>
    </div>
  )
}
